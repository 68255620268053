import { API_BASE, API_ENDPOINT_ADDRESS } from '@/constants/index.js'
import { useI18n } from 'vue-i18n'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { isAddressCheckResponseValid } from '@/types/api'
import { useAddressLocalStorage } from '@/composables/useLocalStorage'
import { useRouter } from 'vue-router'
import { mapPackages } from '@/utils/mapPackages'
const { setAddressObject } = useAddressLocalStorage()

const loading = ref(false)
const billables = ref()

export function useProspectAddress () {
	const { t } = useI18n({
		useScope: 'global'
	})
	const router = useRouter()

	const localePath = useLocalePath()
	async function getProspectAddressData (searchTerm: string) {
		const { reportError } = useErrorReporter(useBugsnag().notify)
		const formData = {
			address: searchTerm
		}
		loading.value = true
		return await $fetch(`${API_BASE}${API_ENDPOINT_ADDRESS}`, {
			method: 'POST',
			body: formData,
			onResponse ({ response }) {
				if (!response.ok) {
					const errorMessage = response?._data?.error || response?._data?.errors[0].title
					throw new Error(errorMessage)
				}
				const data = response._data
				billables.value = mapPackages(data?.billables)
				if (isAddressCheckResponseValid(data)) {
					const { addressId = 0, zoneType = 'remote', address = searchTerm, accountFound } = data
					const { status } = setAddressObject(address, addressId, zoneType, billables.value, accountFound)
					if (status?.length) {
						accountFound ? router.push({ path: localePath('already-registered') }) : router.push({ path: localePath('get-fiber'), query: { fiber: status } })
					} else {
						reportError('Zone to Status mapping failed: fiber status is undefined')
					}
				}
				loading.value = false
			}
		}).catch((e) => {
			const errorMessage = `${e} - Address: ${formData.address}`
			reportError(errorMessage, true, {
				title: t('address-checker.api-error-toast.title'),
				message: t('address-checker.api-error-toast.body')
			})
			loading.value = false
		})
	}

	return {
		getProspectAddressData,
		loading,
		billables
	}
}
