import type { Billable, MappedPackage } from '@/components/signup/types'

export const mapPackages = (billables: Billable[]): MappedPackage[] => {
	return billables?.filter(billable => billable.billablePackageSpeed).map(({
		billableId,
		billablePackageSpeed,
		billableUnit,
		billablePrice,
		billableFeatures,
		recurringFrequency,
		uniquePlanId,
		latencyMs
	}) => ({
		id: billableId,
		packageSpeed: billablePackageSpeed,
		unit: billableUnit,
		price: billablePrice,
		features: billableFeatures,
		frequency: recurringFrequency,
		uniquePlanId,
		latencyMs
	})) || []
}
