import type { AlertProps } from '@/types/alert'
import type { Zone } from '@/components/signup/types'

interface AddressCheckResponse {
	address: string
	addressId: number
	accountFound: boolean
	zoneType: Zone
}

interface SignupResponse {
	address: number
	zoneType: Zone
	firstName: string
	lastName: string
	email: string
	phone: string
	billableId: number
}

const isAlertResponseValid = (obj: unknown): obj is AlertProps[] => (
	Array.isArray(obj) && obj.every(item =>
		typeof item.message === 'string' &&
		typeof item.title === 'string' &&
		typeof item.active === 'boolean' &&
		(item.variant === undefined ||
			[ 'danger', 'warning', 'info', 'success' ].includes(item.variant))
	)
)

const isAddressCheckResponseValid = (obj: unknown): obj is AddressCheckResponse => (
	typeof obj === 'object' &&
	obj !== null &&
	typeof (obj as any).address === 'string' &&
	typeof (obj as any).addressId === 'number' &&
	typeof (obj as any).accountFound === 'boolean' &&
	[ 'in_service', 'remote', 'extended' ].includes((obj as any).zoneType)
)

const isSignupResponseValid = (obj: unknown): obj is SignupResponse => (
	typeof obj === 'object' &&
	obj !== null &&
	typeof (obj as any).address === 'number' &&
	[ 'in_service', 'remote', 'extended' ].includes((obj as any).zoneType) &&
	typeof (obj as any).firstName === 'string' &&
	typeof (obj as any).lastName === 'string' &&
	typeof (obj as any).email === 'string' &&
	typeof (obj as any).phone === 'string' &&
	typeof (obj as any).billableId === 'number'
)

export { isAlertResponseValid, isAddressCheckResponseValid, isSignupResponseValid, AddressCheckResponse, SignupResponse }
